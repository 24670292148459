<template>
    <div class="maps-container app-view">
        <b-container>
            <h3 class="goplay-store__title">Maps</h3>

            <maps-devices
                :children="children"
                :ids="ids"
                :selected-device-id="deviceId"
                @change-device="changeDevice"
            ></maps-devices>
            <div v-if="deviceId !== ''">
                <maps-devices-table-section
                    :key="deviceId"
                    :device-id="deviceId"
                ></maps-devices-table-section>
            </div>
        </b-container>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { getWatches } from '@/api/graphql/requests';
    import MapsDevices from '@/components/maps/MapsDevices.vue';
    import MapsDevicesTableSection from '../components/maps/MapsDevicesTableSection.vue';

    export default {
        name: 'Home',
        components: {
            MapsDevices,
            MapsDevicesTableSection,
        },
        data: () => ({
            deviceId: '',
            ids: [],
        }),
        computed: {
            ...mapGetters({
                children: 'user/getChildren',
            }),
        },
        methods: {
            changeDevice(id) {
                this.deviceId = id;
            },
        },
        async created() {
            const data = await getWatches();
            data.watches.forEach((watch, index) => {
                this.ids[index] = watch.id;
                if (index === 0) {
                    this.deviceId = watch.id;
                }
            });
        },
    };
</script>
