<template>
    <div class="mt-4">
        <b-tabs content-class="mt-4" pills class="watch-infomation__tabs">
            <b-tab title="View by:" disabled></b-tab>
            <b-tab
                active
                title="All"
                @click="() => (date = [...day0, ...day1, ...day2, ...day3])"
            >
            </b-tab>
            <b-tab title="Today" @click="() => (date = day0)"> </b-tab>
            <b-tab title="Yesterday" @click="() => (date = day1)"> </b-tab>
            <b-tab title="48-72 hours ago" @click="() => (date = day2)">
            </b-tab>
            <b-tab title="72-96 hours ago" @click="() => (date = day3)">
            </b-tab>
        </b-tabs>
        <b-tabs
            v-if="date && date.length > 0"
            content-class="mt-3"
            align="center"
        >
            <b-tab title="Map">
                <div>
                    <GmapMap
                        :center="{
                            lat: Number(date[0].lat),
                            lng: Number(date[0].lng),
                        }"
                        :zoom="12"
                        map-type-id="terrain"
                        style="width: 100%; height: 500px"
                    >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in date"
                            :position="{
                                lat: Number(m.lat),
                                lng: Number(m.lng),
                            }"
                            :clickable="true"
                            :draggable="true"
                            @click="
                                center = {
                                    lat: Number(date[0].lat),
                                    lng: Number(date[0].lng),
                                }
                            "
                            :icon="getIcon(m.day)"
                        />
                        <!-- Add lines -->
                        <GmapPolyline
                            :path="polylinePath"
                            :options="polylineOptions"
                        />
                    </GmapMap>
                </div>
            </b-tab>
            <b-tab title="Data" active>
                <div class="map-page__table app-table">
                    <b-button
                        class="float-right"
                        size="sm"
                        @click="downloadTable"
                        variant="primary"
                        >Download Table</b-button
                    >
                    <b-table
                        class="text-center"
                        :items="date"
                        :fields="locationDataHeader"
                        :selectable="false"
                    >
                        <template #cell(latlng)="i">
                            <a
                                :href="`https://www.google.com/maps/search/?api=1&query=${i.item.lat},${i.item.lng}`"
                                target="_blank"
                            >
                                {{ i.item.lat }}, {{ i.item.lng }}
                            </a>
                        </template>
                        <template #cell(cellTower)="i">
                            {{ i.item.extra.cellTower.id }}_{{
                                i.item.extra.cellTower.mcc
                            }}_{{ i.item.extra.cellTower.mnc }}
                        </template>
                        <template #cell(strength)="i">
                            {{ i.item.extra.cellTower.strength }}
                            {{ i.item.extra.cellTower.strength && 'dBm' }}
                        </template>
                        <template #cell(wifi)="i">
                            <div class="wifi-row">
                                {{ getWifiName(i.item) }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
        </b-tabs>
        <div v-else class="map-page__no-data">No data was found</div>
    </div>
</template>

<script>
    import moment from 'moment';
    import 'moment-timezone';
    import { mapGetters } from 'vuex';
    import { getDeviceLocationHistory } from '@/api/graphql/requests';

    export default {
        name: 'MapsDevicesTableSection',
        components: {},
        props: {
            deviceId: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                breadcrumbs: [
                    {
                        text: 'Devices',
                        href: `/devices/info/${this.$route.params.uid}`,
                    },
                    {
                        text: 'Map',
                        active: true,
                    },
                ],
                mapZoomLevel: 5,
                error: false,
                day0: [],
                day1: [],
                day2: [],
                day3: [],
                date: [],
                noData: false,
                processingSearch: null,
                polylineOptions: {
                    strokeColor: 'black', // Color of the polyline
                    strokeOpacity: 0.8, // Opacity of the polyline
                    strokeWeight: 2, // Width of the polyline
                },
            };
        },
        methods: {
            getIcon(day) {
                let color = '';
                switch (day) {
                    case 'day0':
                        color = 'red';
                        break;
                    case 'day1':
                        color = 'blue';
                        break;
                    case 'day2':
                        color = 'green';
                        break;
                    case 'day3':
                        color = 'yellow';
                        break;

                    default:
                        color = 'red';
                        break;
                }
                const icon = {
                    url: `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
                    strokeColor: 'white',
                };

                return icon;
            },
            getWifiName(item) {
                if (item.extra.wifiAccessPoints.length > 0) {
                    const text = item.extra.wifiAccessPoints
                        .map((i) => `${i.mac},${i.strength}`)
                        .join('|');
                    return text;
                }
                return '';
            },
            formateData(data, day) {
                if (data.length === 0) {
                    return data;
                }
                const formatedData = JSON.parse(JSON.stringify(data));
                data.forEach((d, index) => {
                    const time = new Date(d.time * 1000);
                    const repTime = new Date(d.reportTime * 1000);
                    formatedData[index].time = moment(time)
                        .tz('Europe/Berlin')
                        .format('DD/MM/YYYY HH:mm:ss');
                    formatedData[index].reportTime = moment(repTime)
                        .tz('Europe/Berlin')
                        .format('DD/MM/YYYY HH:mm:ss');
                    formatedData[index].br = `${d.br}%`;
                    if (d.extra && typeof d.extra.isCharging !== 'undefined') {
                        formatedData[index].charging = d.extra.isCharging
                            ? 'YES'
                            : 'NO';
                    }

                    if (d.extra && typeof d.extra.radius !== 'undefined') {
                        formatedData[index].rad = d.extra.radius;
                    }

                    if (
                        d.extra &&
                        d.extra.cellTower &&
                        typeof d.extra.cellTower.strength !== 'undefined'
                    ) {
                        formatedData[index].strength =
                            d.extra.cellTower.strength;
                    } else {
                        formatedData[index].strength = 'N/A';
                    }
                    formatedData[index].type = d.extra.locType;
                    formatedData[index].safezone = d.safezone !== '' ? 1 : 0;
                    if (formatedData[index].locateType === 'gps') {
                        formatedData[index].network = '';
                    } else {
                        formatedData[index].network =
                            d.extra.cellTower.id >= 65536 ? 'LTE' : 'GSM';
                    }
                    formatedData[index].day = day;
                });
                return formatedData;
            },
            downloadTable() {
                let csv =
                    'Time,Report Time,LatLng,rad,Locate Type,Strength,Cell Tower,is_sz,Network,Wifi,Br,Charging\n';

                this.date.forEach((item) => {
                    const latlng = `${item.lat}, ${item.lng}`;
                    const cellTower = `${item.extra.cellTower.id}_${item.extra.cellTower.mcc}_${item.extra.cellTower.mnc}`;
                    const strength = `${item.extra.cellTower.strength} dBm`;
                    const wifi = this.getWifiName(item);
                    const { time } = item;
                    const { reportTime } = item;
                    const rad = item.radius;
                    const { locateType } = item;

                    // eslint-disable-next-line camelcase
                    const is_sz = item.safezone;
                    const { network } = item;
                    const { br } = item;
                    const { charging } = item;

                    // eslint-disable-next-line camelcase
                    csv += `"${time}","${reportTime}","${latlng}","${rad}","${locateType}","${strength}","${cellTower}","${is_sz}","${network}","${wifi}","${br}","${charging}"\n`;
                });

                // Create a Blob and create a URL for the Blob
                const blob = new Blob([csv], {
                    type: 'text/csv;charset=utf-8;',
                });
                const url = URL.createObjectURL(blob);

                // Create a download anchor element and click it to initiate the download
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.setAttribute('download', 'table_data.csv');
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Clean up by revoking the object URL
                URL.revokeObjectURL(url);
            },
        },
        computed: {
            ...mapGetters({
                deviceData: 'device/getDeviceData',
            }),
            polylinePath() {
                // Create an array of LatLng objects from marker positions
                return this.date.map((m) => ({
                    lat: Number(m.lat),
                    lng: Number(m.lng),
                }));
            },

            locationDataHeader() {
                return [
                    'time',
                    'reportTime',
                    [
                        {
                            key: 'latlng',
                            label: 'Lat , Lng',
                        },
                    ],
                    'radius',
                    'locateType',
                    'step',
                    'strength',
                    'cellTower',
                    [
                        {
                            key: 'safezone',
                            label: 'is_sz',
                        },
                    ],
                    'network',
                    'wifi',
                    'br',
                    'charging',
                ];
            },
        },
        async created() {
            this.error = false;
            this.noData = false;

            this.processingSearch = true;
            const locationHistory = await getDeviceLocationHistory(
                this.deviceId,
            );

            if (locationHistory) {
                this.day0 = this.formateData(locationHistory.day0, 'day0');
                this.day1 = this.formateData(locationHistory.day1, 'day1');
                this.day2 = this.formateData(locationHistory.day2, 'day2');
                this.day3 = this.formateData(locationHistory.day3, 'day3');
                this.processingSearch = false;
                this.date = [
                    ...this.day0,
                    ...this.day1,
                    ...this.day2,
                    ...this.day3,
                ];
                return;
            }
            this.noData = true;
            this.processingSearch = false;
        },
    };
</script>

<style></style>
