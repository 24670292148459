<template>
    <div class="maps-devices">
        <div
            v-for="(child, index) in children"
            :key="child.ward.id"
            class="device-card"
            :class="{
                'device-card-selected': selectedDeviceId === ids[index],
            }"
            @click="changeDevice(index)"
        >
            <div>
                {{ child.ward.name }}
            </div>
            <div>
                {{ child.ward.countryPhoneCode }}
                {{ child.ward.mobilePhoneNumberNational }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MapsDevices',
        props: {
            children: {
                type: Array,
                required: true,
            },
            ids: {
                type: Array,
                required: true,
            },
            selectedDeviceId: {
                type: String,
                required: true,
            },
        },
        computed: {},
        methods: {
            changeDevice(index) {
                this.$emit('change-device', this.ids[index]);
            },
        },
    };
</script>

<style></style>
